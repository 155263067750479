import React, { useState,useEffect } from "react";
import MexcDatos from "@/Page/Mexc/datos/index.jsx"
import SuyoDatos from "@/Page/Suyo/datos/index.jsx"
import CountryTabs from "@/components/CountryTabs";
import "./datos.less"
const CommonDatos = ({suyo}) =>{
    const [checkType,setCheckType] = useState(1);
    useEffect(()=>{
        console.log('suyo',suyo);
        if(suyo){
            setCheckType(parseInt(suyo))
        }
    },[])
    return(
        <div className="commonDatos">
         <CountryTabs checkType={checkType} setCheckType={setCheckType} />
        {checkType ===1? <SuyoDatos/> : <MexcDatos/>}
        </div>
    )
}

export default CommonDatos;
