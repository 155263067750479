import React, { useState, useEffect } from "react";

import "./fraud.less";
import CountryTabs from "@/components/CountryTabs";
import SuyoFraud from "@/Page/Suyo/fraudPrevention/index";
import MexcFraud from "@/Page/Mexc/fraudPrevention/index";
const FraudTabs = ({ suyo }) => {
  const [checkType, setCheckType] = useState(parseInt(suyo));
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const suyo = urlParams.get("suyo");
    if (suyo) {
      setCheckType(parseInt(suyo));
    }
  }, []);
  return (
    <div className="fraud-container">
      <CountryTabs checkType={checkType} setCheckType={setCheckType} />
      {checkType === 1 ? <SuyoFraud /> : <MexcFraud />}
    </div>
  );
};

export default FraudTabs;
