import React from "react";
import "./index.less";
import titleImg from "../../assets/imgs/cupones/title.png";
import avatarImg from "../../assets/imgs/cupones/avatar.png";
import guide1 from "../../assets/imgs/cupones/guide1.png";
import guide2 from "../../assets/imgs/cupones/guide2.png";
const Cupones = () => {
  return (
    <div className="cupones-container">
      <img className="title-img" src={titleImg} alt="titleImg" />

      <div className="cupone-main">
        <div className="title">
          <p>¿Cómo puedo comprobar mis cupones?</p>
        </div>
        <div className="desc-selection">
          <span>
            Abra la pestaña “{<img src={avatarImg} alt="avatarImg" />}” y haga
            clic en “Cupón” para comprobar todos sus cupones.
          </span>
          <img className="guideImg" src={guide1} alt="guide1" />
        </div>
      </div>

      <div className="cupone-main">
        <div className="title">
          <p>¿Cómo puedo utilizar los cupones al obtener un préstamo?</p>
        </div>
        <div className="desc-selection">
          <span>
            Haga clic en el botón “Cupón” como sigue.{<br/>}Seleccione el Cupón Del
            Préstamo.
          </span>
          <img className="guideImg"src={guide2} alt="guide2" />
        </div>
      </div>

      <div className="bottom-desc1">
        ¿Por Qué No Encuentro Los Cupones Cuando {<br/>}Quiero Obtener Un Préstamo O Un
        Reembolso?
      </div>
      <div className="bottom-desc2">
      1. Para cada factura, solo se puede utilizar un cupón {<br/>}
      para pedir prestado o para reembolsar.{<br/>}
       No encontrará la entrada si el cupón ya se utiliza en{<br/>}
        esta factura (especialmente en el reembolso).{<br/>}
      2. Si no hay ningún cupón disponible que cumpla las {<br/>}condiciones, no encontrará la entrada a los cupones.
      </div>
    </div>
  );
};

export default Cupones;
