
export const oldCopyToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';  
    textArea.style.top = '0';           
    textArea.style.left = '-9999px';    
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        document.execCommand('copy');
    } catch (err) {
      console.log('Copy failed!');
    } finally {
      document.body.removeChild(textArea);
    }
  };