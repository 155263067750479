import React, { useEffect, useState } from "react";
import "./index.less";
import guideImg from "../../../assets/imgs/phoneLost/appGuide.png";
import phoneIcon from "../../../assets/imgs/phoneLost/phone.png";
// import guideImg from "../../../assets/imgs/fraudPrevention/GuideSuyo.png";

import bg from "../../../assets/imgs/phoneLost/main.png";
import idCardMx from "../../../assets/imgs/phoneLost/idCardMx.png";
import qrCardMx from "../../../assets/imgs/phoneLost/qrCardMx.png";
import handheldCardMx from "../../../assets/imgs/phoneLost/handheldCardMx.png";
import {mxServicePhone} from "../../../constants/common"
import {oldCopyToClipboard} from "../../../utils/utils"

const FraudPrevention = () => {
  const [phone] = useState(mxServicePhone);
  const [copyMessage, setCopyMessage] = useState("");

 const copyToClipboard = (text) => {
    setCopyMessage('Copied!'); 
    oldCopyToClipboard(text);
    setTimeout(() => setCopyMessage(''), 2000);
  };

  useEffect(() => {
    // fetch("api/loan/config/global/service?appId=1")
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     if(data.data){
    //         setPhone(data.data.phones[0].phone)
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("There was a problem with the fetch operation:", error);
    //   });
  }, []);
  return (
    <div className="pl-bg">
      <div></div>
      <img className="bgImg" src={bg} alt="pl-bg" />
      <div className="pl-container">
        <div className="title">Primero</div>
        <div className="content">
          <span className="desc">
            Asegúrase de que te has registrado en PrestMan y obtienes el
            préstamo al menos una vez.
          </span>
          <img className="guideImg" src={guideImg} alt="guide" />
        </div>
      </div>
      <div className="segundo-container">
        <div className="title">Segundo</div>
        <div className="content">
          <span className="desc">
            Envíe una solicitud de cambio de número de teléfono enviando un
            mensaje de WhatsApp al.
          </span>
        </div>
        <div className="phone-selection" onClick={()=> copyToClipboard(phone)}>
          <img src={phoneIcon} alt="phoneIcon" />
          <span>{phone}</span>
        </div>
        <div className="detail-selection">
          <p className="detail-title">Incluyendo los siguientes detalles:</p>
          <div className="detail-desc">
            <p>(1) Su nombre completo：</p>
            <p>(2) Antiguo número de teléfono:</p>
            <p>(3) Nuevo número de teléfono:</p>
            <p className="small">
              (Asegúrase de que el nuevo teléfono no se haya registrado en
              PrestMan)
            </p>
            <p>(4) 3 fotos por archivo adjunto：</p>
            <p className="small">
              (Por favor, utilice el mismo ID con el que hiciste una solicitud
              en PrestMan antes)
            </p>
          </div>
        </div>
        <div className="card-selection">
          <p>1.Anverso/Reverso de INE/IFE</p>
          <img src={idCardMx} alt="idCardMx" />
          <img src={qrCardMx} alt="qrCardMx" />
          <p>2.Por Favor Tenga Su tarjeta INE/IFE</p>
          <img src={handheldCardMx} alt="handheldCardMx" />
        </div>
      </div>
      {copyMessage && <span className="copy-tooltip">{copyMessage}</span>}
      <div className="footer"></div>
    </div>
  );
};

export default FraudPrevention;
