import React, { useEffect, useState } from "react";
import "./index.less";
import guideImg from "../../../assets/imgs/fraudPrevention/fpGuideMx.png";
// import guideImg from "../../../assets/imgs/fraudPrevention/GuideSuyo.png";

import bg from "../../../assets/imgs/fraudPrevention/fpBg.png";
import titleImg from "../../../assets/imgs/fraudPrevention/fpTitle.png";
import MXImg from "../../../assets/imgs/MX.png";
import {mxServicePhone} from "../../../constants/common"
import {oldCopyToClipboard} from "../../../utils/utils"

const FraudPrevention = () => {
  const [phone] = useState(mxServicePhone);
  const [copyMessage, setCopyMessage] = useState('');
  
  const copyToClipboard = (text) => {
    setCopyMessage('Copied!'); 
    oldCopyToClipboard(text);
    setTimeout(() => setCopyMessage(''), 2000);
  };

  useEffect(() => {
    // fetch("api/loan/config/global/service?appId=1")
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     if(data.data){
    //         setPhone(data.data.phones[0].phone)
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("There was a problem with the fetch operation:", error);
    //   });
  }, []);
  return (
    <div className="fp-bg">
      <img className="bgImg" src={bg} alt="fp-bg" />
      <div className="fp-container">
        <div className="title">
          <img src={titleImg} alt="titleImg" />
        </div>
        <div className="content">
          <span className="desc">
            Últimamente, un estafador se hizo pasar por un miembro del personal
            de nuestra empresa, afirmó falsamente que el sistema de pago de la
            aplicación estaba defectuoso y pidió a los clientes que pagaran en
            sus cuentas personales. Recuerde utilizar únicamente la aplicación
            oficial y la página de pago para obtener el código de pago y no se
            deje engañar. Si tiene alguna pregunta, comuníquese con el servicio
            de atención al cliente.
          </span>
          <div className="phone"  onClick={() => copyToClipboard(phone)}>
            <img src={MXImg} alt="MXImg"/>
            {phone}
          </div>
          
          <div className="desc1">
            Comprueba los canales y códigos de reembolso a través de la
            aplicación.
          </div>
          <img className="guideImg" src={guideImg} alt="guide" />
          <div className="desc2">
            Pulse para recibir el código oficial de reembolso
          </div>
        </div>
      </div>
      {copyMessage && <span className="copy-tooltip">{copyMessage}</span>}
      <div className="footer"></div>
    </div>
  );
};

export default FraudPrevention;
