import React,{useState,useEffect} from "react";

import "./phoneLost.less";
import CountryTabs from "@/components/CountryTabs";
import SuyoPhoneLost from "@/Page/Suyo/phoneLost/index";
import MexcPhoneLost from "@/Page/Mexc/phoneLost/index";
const PhoneLost = ({suyo}) => {
  const [checkType, setCheckType] = useState(parseInt(suyo));
  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const suyo = urlParams.get('suyo');
    if(suyo){
     setCheckType(parseInt(suyo));
    }
  },[])
  return (
    <div className="phoneLost-container">
      <CountryTabs checkType={checkType} setCheckType={setCheckType} />
      {checkType === 1? <SuyoPhoneLost/> : <MexcPhoneLost/>}
    </div>
  );
};

export default PhoneLost;
