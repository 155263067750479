import React, { Component } from "react";
import Home from "./Puerta";
import Politica from "./Page/suyoDatos/politica";
import Terminos from "./Page/suyoDatos/terminos";
// import PoliticaMexc from "./Page/suyoDatos/politicaMexc";
// import TerminosMexc from "./Page/suyoDatos/terminosMexc";
// import PoliticaSuyo from "./Page/suyoDatos/politicaSuyo";
// import TerminosSuyo from "./Page/suyoDatos/terminosSuyo";

// import Datos from "./Page/suyoDatos/datos";
import Datos from "@/Page/common/datos";
import ErrorInfo from "./Page/ErrorInfo";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

// import Mexc from "./Page/Mexc/datos";
// import Suyo from "./Page/Suyo/datos";
import FraudPrevention from "@/Page/common/fraud.jsx";
import PhoneLost from "@/Page/common/phoneLost.jsx";
// import PhoneLostMx from "./Page/Mexc/phoneLost";
// import PhoneLostSuyo from "./Page/Suyo/phoneLost";
import Cupones from "./Page/cupones";


const browserHistory = createBrowserHistory();
class App extends Component {
  render() {
    console.log("----App----");


    return (
      <Router history={browserHistory}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/errorInfo" component={ErrorInfo} />

          {/* pmPolitica pmTerminos  默认秘鲁版本 可弹框切换 */}
          {/* pmPoliticaMexc pmTerminos  秘鲁版本*/}
          {/* pmPoliticaMexc pmTerminosMexc  墨西哥版本 */}
          <Route exact path="/politica" component={Politica} />
          <Route exact path="/terminos" component={Terminos} />

          <Route exact path="/politicamexc" render={(props) => <Politica {...props} suyo={2} />} />
          <Route exact path="/terminosmexc" render={(props) => <Terminos {...props} suyo={2} />} />

          <Route exact path="/politicasuyo" render={(props) => <Politica {...props} suyo={1} />} />
          <Route exact path="/terminossuyo" render={(props) => <Terminos {...props} suyo={1} />}/>



          {/* suyoDatos  默认秘鲁版本 可弹框切换 */}
          {/* mexcTosuyo  墨西哥版本 */}
          {/* suyoTomexc  秘鲁版本 */}
          <Route exact path="/suyodatos" component={Datos} />
          <Route exact path="/suyotomexc" render={(props) => <Datos {...props} suyo={1} />} />
          <Route exact path="/mexctosuyo" render={(props) => <Datos {...props} suyo={2} />} />

          <Route exact path="/mexcFraudPrevention"  render={(props) => <FraudPrevention {...props} suyo={2} />} />
          <Route exact path="/suyoFraudPrevention" render={(props) => <FraudPrevention {...props} suyo={1} />} />
          <Route exact path="/mexcPhoneLost" render={(props) => <PhoneLost {...props} suyo={2} />} />
          <Route exact path="/suyoPhoneLost" render={(props) => <PhoneLost {...props} suyo={1} />} />

          <Route exact path="/cuponesDesc" component={Cupones} />
          
          <Route path="*" component={() => <Redirect to="/" />} />
        </Switch>
      </Router>
    );
  }
}


export default App;
