import React from "react";
import "./countryTabs.less"
import PE from "@/assets/imgs/PE.png";
import MX from "@/assets/imgs/MX.png";
const CountryTabs =({checkType,setCheckType}) =>{
    console.log(checkType);
    return (
      <div className={"countryTabs"}>
        <div
          onClick={() => {
            setCheckType(1);
          }}
          className={["tab1", checkType === 1 && "check"].join(" ")}
        >
          Peru <img src={PE} className="countrylogo" alt="localCountry" />
        </div>
        <div
          onClick={() => {
            setCheckType(2);
          }}
          className={["tab2", checkType === 2 && "check"].join(" ")}
        >
          Mexico <img src={MX} className="countrylogo" alt="localCountry" />
        </div>
      </div>
    );
}

export default CountryTabs;